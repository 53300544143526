<template>
  <div>
    <Header>我的账户</Header>
    <div class="cardList">
      <div
        class="cardItem"
        v-for="item in accountList"
        :key="item.id"
        @click="goTo(item)"
      >
        <div class="left" :style="{ background: item.background }">
          <img :src="item.icon" alt="" />
        </div>
        <div class="meddle">{{ item.name }}</div>
        <div class="icon"></div>
      </div>
    </div>

    <!--  -->
    <div class="selectPayType" v-if="isShowSign">
      <div class="selectPayTypeBox"></div>
      <div class="bg">
        <div class="content">请在APP内查看卡券</div>
        <div class="btnGroup">
          <div class="unCompleted" @click="isShowSign = false">取消</div>
          <div class="completed" @click="openApp">去下载app</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wallet from '../../../assets/images/account/wallet.png';
import reward from '../../../assets/images/account/reward.png';
import key from '../../../assets/images/account/key.png';
import card from '../../../assets/images/account/card.png';
import jiaozi from '../../../assets/images/account/jiaozi.png';
export default {
  data() {
    return {
      isShowSign: false,
      accountList: [
        {
          id: 0,
          name: '余额钱包',
          icon: wallet,
          background: '#F5FFFF',
          path: '/money',
        },
        {
          id: 1,
          name: '红包奖励',
          icon: reward,
          background: '#FFFAEF',
          path: '/redEnvelopes',
        },
        // {
        //   id: 2,
        //   name: '我的钥匙',
        //   icon: key,
        //   banlance: '100个',
        //   background: '#EFFEFF',
        //   path: '/keys',
        // },
        {
          id: 3,
          name: '我的卡券',
          icon: card,
          background: '#F3F6FF',
          path: '/cards',
        },
        {
          id: 4,
          name: '我的交子',
          icon: jiaozi,
          background: '#FBFFF0',
          path: '/jiaozi',
        },
      ],
    };
  },
  mounted() {},
  methods: {
    goTo(item) {
      if (item.path === '/cards' || item.path === '/jiaozi') {
        this.isShowSign = true;
        return;
      }
      this.$router.push(item.path);
    },
    openApp() {
      window.open('https://v.hxnft.shop/appDownload/index.html');
      this.isShowSign = false;
    },
  },
};
</script>

<style lang="less" scoped>
.cardList {
  padding: 15px;
  .cardItem {
    width: 100%;
    height: 69px;
    background: #ffffff;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 7px 15px;
    box-sizing: border-box;
    margin-bottom: 20px;
    .left {
      padding: 10px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      img {
        width: 25px;
        vertical-align: middle;
      }
    }
    .meddle {
      flex: 1;
      font-size: 16px;
      color: #000;
      font-weight: 400;
    }
    .right {
      font-size: 16px;
      color: #000;
      font-weight: 500;
    }
    .icon {
      width: 8px;
      display: block;
      height: 8px;
      border-top: 2px solid #999999;
      border-right: 2px solid #999999;
      transform: rotate(45deg);
      margin-left: 5px;
    }
  }
}
.selectPayType {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 99999;
  .selectPayTypeBox {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  .bg {
    width: 80%;
    height: 160px;
    background: #ffffff;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .content {
      font-size: 16px;
      color: #666666;
      margin: 35px 0 35px;
      text-align: center;
    }
    .btn {
      width: 60%;
      height: 35px;
      margin: 0 auto;
      background: #0754d3;
      border-radius: 3px;
    }
    .btnGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      div {
        background: #0754d3;
        border-radius: 3px;
        font-size: 14px;
        color: #fff;
        width: 90px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
        &.unCompleted {
          border: 1px solid #0754d3;
          background: transparent;
          color: #0754d3;
        }
      }
    }
  }
}
</style>
